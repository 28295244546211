<template>
  <div>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
            class="text-right"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              persistent-hint
              dense
              @keyup.enter="getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 })"
              @keydown.esc="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            lg="2"
            md="5"
            sm="11"
            class="text-right"
          >
            <!-- date dialog -->
            <v-dialog
              ref="dialog"
              v-model="dateDialog"
              :return-value.sync="selectedDate"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-filter
                  </v-icon>
                  {{ selectedDate }}
                </v-chip>
              </template>
              <v-date-picker
                v-model="selectedDate"
                color="#37474F"
                scrollable
                :max="maxStartDate"
                @input="handleDateInput(selectedDate)"
              >
                <v-btn
                  text
                  color="primary"
                  @click="dateDialog = false"
                >
                  Cancel
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            md="2"
            sm="1"
            lg="3"
            class="text-right"
          >
            <v-autocomplete
              v-model="selectedCompany"
              :items="companiesList"
              item-text="name"
              item-value="id"
              label="Company*"
              single-line
              append-icon="mdi-office-building-plus-outline"
              outlined
              dense
              @input="getRecordsFromServer()"
            />
          </v-col>
          <v-col lg="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  dark
                  outlined
                  color="#37474F"
                  small
                  v-on="on"
                  @click="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 })"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-filter-off-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>All records</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="attendance"
        disable-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30]
        }"
        :loading="updateProgress"
        :server-items-length="totalRecords"
        class="elevation-1"
        mobile-breakpoint="100"
        light
        @pagination="getRecordsFromServer"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span class="info-container">
                {{ item.app_user.first_name }} {{ item.app_user.last_name }}
              </span>
            </td>
            <td>
              <span v-if="item.app_user.company !== null">{{ item.app_user.company.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span
                v-if="item.app_user.app_user_role !== null"
                class="type-style"
              > {{ item.app_user.app_user_role ==='site-manager'?'site manager': item.app_user.app_user_role }} </span>
              <span v-else>
                N/A
              </span>
            </td>
            <td>{{ item.app_user.worker_category?item.app_user.worker_category.name:'' }}</td>
            <td>
              <v-chip
                v-for="type in item.app_user.types"
                :key="type"
                class="ma-2"
                outlined
              >
                {{ type }}
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner :loading="loading" />
    <v-dialog
      v-model="openUserModal"
      width="600px"
    >
      <v-card class="pa-6 card-container">
        <div class="close-button">
          <v-btn
            fab
            x-small
            outlined
            @click="openUserModal = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-skeleton-loader
          v-if="modalLoading == true"
          :loading="modalLoading"
          type="avatar, heading, sentences"
          color="#eceff1"
        />
        <div v-else>
          <div
            class="card-wrapper "
          >
            <div class="user-details">
              <v-icon
                size="100"
                color="rgb(55, 71, 79)"
              >
                mdi-account-circle
              </v-icon>
              <p class="text-h3 font-weight-bold mb-1">
                {{ userDetails.first_name }} {{ userDetails.last_name }}
              </p>
              <p class="contacts-container">
                <font>
                  {{ userDetails.phone_number }}
                </font>
              </p>
            </div>
            <v-row class="justify-center pa-5">
              <v-expansion-panels accordion>
                <v-expansion-panel class="my-panel">
                  <v-expansion-panel-header>
                    Emergency Contacts
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <div v-if="!isUserDetailsAvailable">
                      <font class="no-contacts">
                        No Contacts Added
                      </font>
                    </div>
                    <div
                      v-for="(item, i) in userDetails.emergency_contact"
                      v-else
                      :key="i"
                    >
                      <p>
                        <font class="status-font-style">
                          Name:
                        </font>
                        <font class="capitalize font-style-text">
                          {{ item.name }}
                        </font>
                      </p>
                      <p>
                        <font class="status-font-style">
                          Number:
                        </font>
                        <font class="font-style-text">
                          {{ item.phone_number }}
                        </font>
                      </p>
                      <p>
                        <font class="status-font-style">
                          Relation:
                        </font>
                        <font class="capitalize font-style-text">
                          {{ item.relation }}
                        </font>
                      </p>
                      <hr>
                      <br>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="my-panel">
                  <v-expansion-panel-header>
                    CheckIn Types
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <br>
                    <div>
                      <p>
                        <font class="font-style-text">
                          <v-chip
                            v-for="type in userDetails.types"
                            :key="type"
                            class="mr-1"
                          >
                            {{ type }}
                          </v-chip>
                        </font>
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

  <script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
      name: 'OffsiteAttendance',
      components: {
        'centre-spinner': spinner,
      },
      filters: {
          arrangePretty (val) {
            return val.join(', ');
          },
          convertToLocal (stringDatetime) {
            return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
          },
        },
      data () {
          return {
              search: null,
              showForm: false,
              loading: false,
              selectedDate: '',
              dateDialog: false,
              headers: [
                  { text: 'Name', align: 'start', value: 'first_name' },
                  { text: 'Company', value: 'company' },
                  { text: 'Role', value: 'role' },
                  { text: 'Category', value: 'category' },
                  { text: 'Checkin types', value: 'types' },
              ],
              selectedCompany: null,
              updateProgress: false,
              openUserModal: false,
              modalLoading: false,
              maxStartDate: moment().format('YYYY-MM-DD'),
          };
      },
      computed: {
          isSearchAdded () {
            if (this.search === null || this.search === '') {
              return false;
            }
            return true;
          },
          searchHint () {
            return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
          },
          companiesList () {
            return this.$store.getters['companies/getCompaniesList'];
          },
          paginatedAttendanceStructure () {
              return this.$store.getters['attendance/getOffsiteAttendance'];
          },
          attendance () {
            const records = this.paginatedAttendanceStructure;
            return records.data;
          },
          totalRecords () {
            if (Object.keys(this.paginatedAttendanceStructure).length > 0) {
              return this.paginatedAttendanceStructure.total;
            }
            return 0;
          },
          userDetails () {
            return this.$store.getters['appusers/getAppUserDetails'];
          },
          isUserDetailsAvailable () {
            if (Object.keys(this.userDetails).length > 0 && this.userDetails.emergency_contact !== undefined && this.userDetails.emergency_contact.length > 0) {
                return true;
              }
            return false;
          },
      },
      watch: {
      // selectedDate (newVal) {
      //   if (newVal) {
      //     this.getRecordsFromServer();
      //   }
      // },
      // pageStart (newVal, oldVal) {
      //   if (newVal) {
      //     this.getAllRecordsFromServer();
      //   }
      // },
      // itemsPerPage (newVal) {
      //   if (newVal) {
      //     this.getAllRecordsFromServer();
      //   }
      // },
    },
      async mounted () {
          this.loading = true;
          this.date = new Date();
          this.selectedDate = moment(this.date).format('YYYY-MM-DD');
          await this.getCompaniesList();
        },
      methods: {
        handleDateInput (selectedDate) {
        this.$refs.dialog.save(selectedDate);
        this.getRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
      },
          async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
            this.updateProgress = true;
            this.search = null;
            this.selectedCompany = null;
              await this.$store.dispatch('attendance/fetchOffsiteAttendance', {
                  params: {
                      limit: itemsPerPage,
                      startPage: pageStart,
                      date: this.selectedDate,
                  },
            }).catch(() => {
              this.updateProgress = false;
            });
            this.updateProgress = false;
          },
          async getNonFilteredRecordsFromServer () {
            this.updateProgress = true;
            this.search = null;
            this.selectedCompany = null;
              await this.$store.dispatch('attendance/fetchOffsiteAttendance', {
                  params: {
                      limit: this.itemsPerPage,
                      startPage: this.pageStart,
                      date: this.selectedDate = moment(this.date).format('YYYY-MM-DD'),
                  },
            }).catch(() => {
              this.updateProgress = false;
            });
            this.updateProgress = false;
          },
          async getRecordsFromServer ({ itemsPerPage, pageStart }) {
            this.updateProgress = true;
            await this.$store.dispatch('attendance/fetchOffsiteAttendance', {
                params: {
                  name: this.search === '' ? null : this.search,
                  companyId: this.selectedCompany,
                  limit: itemsPerPage,
                  startPage: pageStart,
                  date: this.selectedDate ? this.selectedDate : moment().format('YYYY-MM-DD'),
                },
            }).catch(() => {
              this.updateProgress = false;
            });
            this.updateProgress = false;
          },
          async getCompaniesList () {
            this.loading = true;
            await this.$store.dispatch('companies/fetchCompaniesList').catch(() => {
              this.loading = false;
            });
            this.loading = false;
          },
         async showAppuserDetails (externalId) {
            this.openUserModal = true;
            this.modalLoading = true;
            await this.$store.dispatch('appusers/fetchAppUserDetails', {
              appUserId: externalId,
            });
            this.modalLoading = false;
          },
      },
  };
  </script>

  <style scoped>
    .v-card {
      margin-top: 0px!important;
    }
    .v-data-table::v-deep th {
      font-size: 12px !important;
      color: #37474F !important;
      font-weight: bold !important;
    }
    .v-data-table::v-deep td {
      font-size: 13px !important;
      color: #37474F !important;
    }
    .v-data-table { background-color: #ECEFF1; }
    .icon-style {
      color: #37474F;
      font-size: 45px;
    }
    .info-container {
      cursor: pointer;
      color: #37474F;
    }
    .all-users-btn {
      margin-bottom: 25px;
    }
    .header {
    display: inline-block;
    width: 100%;
  }
  .chip-container{
    display: flex;
    justify-content: space-between;
  }
  .user-details{
    text-align: center;
  }
  .type-style{
    text-transform: capitalize;
  }
  .card-container {
  position: absolute;
  right: 0;
  height: auto;
  bottom: 0;
  top: 0;
  background: #eceff1;
  max-width: 600px;
}
.close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.v-expansion-panel-header {
  background-color: rgb(207, 216, 220);
}
  </style>
